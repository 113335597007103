<template>
    <div style="margin-top: 5%">
        <!--    会议召开单位-->
        <div>
            <el-select v-model="meetdw" placeholder="请选择召开单位"  style="margin-left: 30px;margin-right: 40px;width: 80%">
                <el-option
                        v-for="item in meetdws"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                />
            </el-select>
            <el-input v-model="meetname" style="width: 80%;margin-right: 40px;margin-left: 30px;;margin-top: 5%" placeholder="请输入会议名称" clearable />
            <el-date-picker
                    v-model="datetime"
                    type="date"
                    placeholder="请选择开会日期"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    style="width: 80%;margin-left: 30px;margin-top: 5%"
            />
            <br>
            <el-time-select
                    v-model="detailtime"
                    start="07:00"
                    step="00:10"
                    end="23:50"
                    placeholder="请选择具体时间"
                    style="width: 80%;margin-left: 30px;margin-top: 5%"/>
        </div>
        <!--    人员信息表格-->
        <div style=";margin-top: 20px">
            <!--      查询出的人员-->
            <vxe-table
                    border
                    resizable
                    :align="allAlign"
                    :data="selectMeetUser"
                    size="small"
                    highlight-hover-row
                    ref="xTable1"
                    :export-config="{}"
            >
                <vxe-column field="a" title="姓名" width="200">
                    <template #default="{ row }">
                        <span v-if="row.name !== '科技信息部调试人员'">{{ row.name }}</span>
                    </template>
                </vxe-column>
                <vxe-column title="操作" width="200" show-overflow>
                    <template #default="{ row }">
                        <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">选择</vxe-button>
                    </template>
                </vxe-column>
            </vxe-table>
            <!--      已选择的人员-->
            <div style="width: 100vw;word-wrap:break-word;margin-top: 5%">
                <el-tag style="width: 100vw;">已选择人员\部门</el-tag>
                <br/>
                <el-button v-for="(item, index) in selectedMeetUser" type="warning" size="mini" style="margin-top: 2%" @click="delMeetUser(item)">{{ item }}</el-button>
            </div>
        </div>
        <!--    提交按钮-->
        <div style="margin-top: 5%;margin-bottom: 5%">
            <el-button  type="danger" style="width: 100vw" @click="upMeet()">提交</el-button>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    name: "add_meet",
    data(){
        return{
            meetdw: '',
            meetdws: [
                {
                    value: '集团公司',
                    label: '集团公司',
                },
                {
                    value: '股份公司',
                    label: '股份公司',
                },
                {
                    value: '项目公司',
                    label: '项目公司',
                },
                {
                    value: '康定项目部',
                    label: '康定项目部',
                },
                {
                    value: '四川公司',
                    label: '四川公司',
                },
                {
                    value: '川藏公司',
                    label: '川藏公司',
                },
                {
                    value: '川藏股指',
                    label: '川藏股指',
                },
            ],
            meetname: '',
            detailtime:'',
            datetime: '',
            meetuser: '',
            allAlign: 'center',
            selectMeetUser: [],
            selectedMeetUser: []
        }
    },
    created() {
        this.getDepartOrNameByBdst()
        if (!this.$route.params.key){
            ElMessage.error('非法跳转!')
        }else {
            this.getDepartOrNameByBdst()
        }
    },
    methods:{
        getDepartOrNameByBdst(){
            this.axios.post('/meetuser/getDepartOrNameByBdst', (response) => {
                for (let i = 0; i < response.obj.length; i++){
                    if (response.obj[i].name === '科技信息部调试人员'){
                        response.obj.splice(i, 1)
                    }
                }
                this.selectMeetUser = response.obj
            },{
                bdst: '项目公司'
            })
        },
        getSelectionEvent(row){
            let arr;
            arr = {name: row.name};
            if (this.selectedMeetUser.indexOf(row.name) === -1){
                this.selectedMeetUser.push(row.name);
            }else {
                ElMessage.error('请不要重复选择!');
            }


        },
        delMeetUser(item){
            let index = this.selectedMeetUser.indexOf(item);
            this.selectedMeetUser.splice(index, 1);
        },
        upMeet(){
            if (this.meetname.length > 7){
                ElMessage.error('会议名称过长，请精简到7个字之内！');
            }else if(this.detailtime === ''){
                ElMessage.error('请选择会议的具体时间！');
            } else{
                this.axios.post('/meetMain/saveMeetMainAndBranch', (response) => {
                  if(response.obj){
                      ElMessage.success('添加成功!')
                      this.meetdw = ''
                      this.meetname = ''
                      this.datetime = ''
                      this.detailtime = ''
                      this.selectedMeetUser = []
                  }else{
                      ElMessage.error('添加失败，请联系科信部!')
                  }
                },{
                    startUnit: this.meetdw,
                    meetName: this.meetname,
                    meetTime: this.datetime + ' ' + this.detailtime,
                    meetUsers: JSON.stringify(this.selectedMeetUser)
                })
            }
        }
    }
}
</script>

<style scoped>

</style>